import React from 'react';
import { App } from '@layouts';
import { TopicPosts } from '@components';
import useTopics from '@hooks/useTopics';
import { language } from 'utils/i18n';

const Topic = ({ id }) => {
  const { topics } = useTopics();
  const topicData = topics.find((t) => t.id == id);
  const topicTitle = topicData ? topicData.titles[language] : '';
  return (
    <App title={topicTitle}>
      <TopicPosts topicId={id} />
    </App>
  );
};

export default Topic;
